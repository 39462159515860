import { BaseProp } from "entities/BaseProp";
import { FilterItems } from "entities/ProductsDTO";
import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface BreadcrumbsProp extends BaseProp {
  department?: string | null;
  productType?: string | null;
  productSeries?: string | null;
  filterItems?: FilterItems;
}

const defaultDeptName = "Product Type";

const Breadcrumbs: FC<BreadcrumbsProp> = ({
  className = "block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400 halloween:text-black",
  department,
  productType,
  productSeries,
  filterItems,
}) => {
  const { departmentList = [] } = filterItems || {};
  const [deptName, setDeptName] = useState(defaultDeptName);

  useEffect(() => {
    getDept();
  }, [filterItems]);

  const getDept = () => {
    if (department) {
      setDeptName(department);
      return;
    }
    if (
      (productSeries || productType) &&
      departmentList &&
      departmentList.length > 0
    ) {
      setDeptName(departmentList[0]?.filterName || defaultDeptName);
      return;
    }
    setDeptName(defaultDeptName);
  };

  return (
    <div className={`nc-Breadcrumbs ${className}`} id="nc-Breadcrumbs">
      <Link
        to={`/product-type${
          deptName !== defaultDeptName ? `/${deptName.toLocaleLowerCase()}` : ""
        }`}
        className=""
      >
        {deptName}
      </Link>
      {productType && (
        <>
          <span className="text-xs mx-1 sm:mx-1.5">/</span>
          <Link to={`/product-type?ptype=${productType}`} className="">
            {productType}
          </Link>
        </>
      )}
      {productSeries && productType && (
        <>
          <span className="text-xs mx-1 sm:mx-1.5">/</span>
          <Link
            to={`/product-series/${productType
              .replace("-", "")
              .replace(/\s+/g, "-")
              .toLocaleLowerCase()}`}
            className=""
          >
            {productSeries}
          </Link>
        </>
      )}
    </div>
  );
};

export default Breadcrumbs;
