import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { logout } from "store/slices/authSlice";
import { removeTokens } from "utils/auth";
import eventEmitter from "utils/eventEmitter";

const NavigationListener: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const removeData = () => {
    dispatch(logout());    
    removeTokens();
    Cookies.remove("_authState");
    Cookies.remove("selectedShippingIdRadio");
    Cookies.remove("cookieselectedDeliveryMethod");
  };

  useEffect(() => {
    const handleNavigation = (path: string) => {
      removeData();
      navigate(path);
      //alert("Your session has expired. Please log in again.");
    };

    eventEmitter.on("navigate", handleNavigation);

    return () => {
      eventEmitter.off("navigate", handleNavigation);
    };
  }, [navigate]);

  return null;
};

export default NavigationListener;
