import axiosInstance from "api/AxiosInstance";
import SkeletonProductType from "containers/ProductType/SkeletonProductType";
import { BaseApiResponse } from "entities/ApiResponse";
import { BaseProp } from "entities/BaseProp";
import { ProductItemRequest, ProductsDTO } from "entities/ProductsDTO";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ProductItems from "./ProductItems";
import handleScrollToEl from "utils/HandleScrollToEl";
import ProductBanner from "./ProductBanner";
import { ProductSortData } from "data/cscdata";
import SideFilter from "./SideFilter";
import CSCProductCard from "components/CSCProductCard";
import Divider from "components/Divider";
import Breadcrumbs from "./Breadcrumbs";
interface ProductsProps extends BaseProp {}

interface ProductFilterRequest extends ProductItemRequest {
  minILum?: number;
  maxILum?: number;
  pageIndex?: number;
  pageSize?: number;
  bannername?: string;
  sortproperty?: string;
  psearch?: string;
}

function toQueryString(
  params: ProductItemRequest,
  keys: (keyof ProductItemRequest)[]
): string {
  const queryString = keys
    .filter((key) => params[key] !== undefined) // Filter out undefined properties
    .flatMap((key) => {
      const value = params[key];
      if (Array.isArray(value)) {
        return value.map(
          (item) =>
            `${encodeURIComponent(
              key.replace("Arr", "").toLowerCase() as string
            )}=${encodeURIComponent(item)}`
        );
      } else if (value !== undefined) {
        return `${encodeURIComponent(key as string)}=${encodeURIComponent(
          value
        )}`;
      } else {
        return [];
      }
    })
    .join("&");

  return queryString;
}

const Products: FC<ProductsProps> = ({ className = "" }) => {
  const navigate = useNavigate();

  const { seriesslug: _seriesSlug } = useParams<{ seriesslug: string }>();
  const [searchParams] = useSearchParams();
  const _dept = searchParams.get("dept");
  const _productType = searchParams.get("ptype");
  const _productSeries = _seriesSlug ? _seriesSlug : searchParams.get("pser");
  const _watt = searchParams.get("watt");
  const _volt = searchParams.get("volt");
  const _ctemp = searchParams.get("ctemp");
  const _fcolor = searchParams.get("fcolor");
  const _cert = searchParams.get("cert");
  const _tradeSize = searchParams.get("tradesize");
  const _minILum = searchParams.get("minILum");
  const _maxILum = searchParams.get("maxILum");
  const _psearch = searchParams.get("psearch");

  const [isShowAccessories, setIsShowAccessories] = useState(true);
  const [dataResult, setDataResult] =
    useState<BaseApiResponse<ProductsDTO> | null>(null);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  {
    /**Init Parameters */
  }

  const defaultFilterRequest: ProductFilterRequest = {
    pageIndex: 1,
    pageSize: 24,
    sortproperty: ProductSortData[0].id,
    deptArr: [],
    pTypeArr: [],
    pserArr: [],
    wattArr: [],
    voltArr: [],
    ctempArr: [],
    fcolorArr: [],
    tradesizeArr: [],
    certArr: [],
    bannername: undefined,
    psearch: undefined,
  };

  const [filterRequest, setFilterRequest] = useState<ProductFilterRequest>({
    ...defaultFilterRequest,
    deptArr: _dept ? [_dept] : [],
    pTypeArr: _productType ? [_productType] : [],
    pserArr: _productSeries ? [_productSeries] : [],
    wattArr: _watt ? [_watt] : [],
    voltArr: _volt ? [_volt] : [],
    ctempArr: _ctemp ? [_ctemp] : [],
    fcolorArr: _fcolor ? [_fcolor] : [],
    certArr: _cert ? [_cert] : [],
    tradesizeArr: _tradeSize ? [_tradeSize] : [],
    minILum:
      _minILum !== null && !isNaN(Number(_minILum))
        ? parseInt(_minILum)
        : undefined,
    maxILum:
      _maxILum !== null && !isNaN(Number(_maxILum))
        ? parseInt(_maxILum)
        : undefined,
    bannername: _productSeries || undefined,
    psearch: _psearch || undefined,
  });

  const handleCheckboxChange = (
    filterProp: keyof ProductItemRequest,
    checked: boolean,
    value: any
  ) => {
    setFilterRequest((prev) => {
      const updatedArray = checked
        ? [...(prev[filterProp] || []), value]
        : (prev[filterProp] || []).filter((item) => item !== value);

      return {
        ...prev,
        [filterProp]: updatedArray,
      };
    });
    handleScrollToEl("scrollSeriesTab");
  };

  const assignParameters = (filterProp: string, filterVal: any) => {
    setFilterRequest((prev) => ({ ...prev, [filterProp]: filterVal }));
  };

  const handleShowOptionalAcc = (isCheck: boolean) => {
    setIsShowAccessories(isCheck);
  };

  const handlePageChange = (pageNumber: number) => {
    assignParameters(`pageIndex`, pageNumber);
    handleScrollToEl("scrollSeriesTab");
  };

  const resetFilterRequest = () => {
    setFilterRequest({
      ...defaultFilterRequest,
      bannername: undefined,
    });
    navigate("/products");
  };

  const fetchData = async () => {
    const response = await axiosInstance
      .post("/products/getProducts", filterRequest)
      .then((response) => {
        setDataResult(response.data);
        setIsPageLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsPageLoading(false);
      });
  };

  useEffect(() => {
    if (_seriesSlug) {
      setFilterRequest({
        ...defaultFilterRequest,
        pserArr: [_seriesSlug],
      });
    }
    if (_psearch) {
      setFilterRequest({
        ...defaultFilterRequest,
        psearch: _psearch,
      });
    }
  }, [_seriesSlug, _psearch]);

  useEffect(() => {
    fetchData();
  }, [filterRequest]);

  useEffect(() => {
    setIsPageLoading(true);
    const selectedKeys: (keyof ProductItemRequest)[] = [
      "deptArr",
      "pTypeArr",
      "pserArr",
      "wattArr",
      "tradesizeArr",
      "ctempArr",
      "fcolorArr",
      "voltArr",
      "certArr",
    ];
    var queryString = toQueryString(filterRequest, selectedKeys);
    if (filterRequest.minILum) {
      queryString += `&${encodeURIComponent("minILum")}=${encodeURIComponent(
        filterRequest.minILum
      )}`;
    }
    if (filterRequest.maxILum) {
      queryString += `&${encodeURIComponent("maxILum")}=${encodeURIComponent(
        filterRequest.maxILum
      )}`;
    }
    if (filterRequest.psearch) {
      queryString += `&${encodeURIComponent("psearch")}=${encodeURIComponent(
        filterRequest.psearch
      )}`;
    }
    navigate(`/products?${queryString}`);
  }, [filterRequest, navigate]);

  const renderProductBody = () => {
    return (
      <>
        {dataResult &&
          (dataResult.data.productItems
            ? renderShowProducts()
            : renderNoProductFound())}
      </>
    );
  };

  const renderShowProducts = () => {
    return (
      <>
        {dataResult?.data.productItems && (
          <main>
            {/* LOOP ITEMS */}
            <div
              className="flex flex-col lg:flex-row scroll-mt-32"
              id="scrollSeriesTab"
            >
              {dataResult?.data.filterItems && (
                <div className="lg:w-1/3 xl:w-1/4 pr-4">
                  <SideFilter
                    isShowAccessories={isShowAccessories}
                    selectedFilter={filterRequest}
                    onChangeOptionalAcc={handleShowOptionalAcc}
                    dataResult={dataResult?.data.filterItems}
                    sortproperty={filterRequest.sortproperty}
                    rangeInitialLumen={[
                      filterRequest.minILum ||
                        dataResult.data.filterItems.initialLumensMin,
                      filterRequest.maxILum ||
                        dataResult.data.filterItems.initialLumensMax,
                    ]}
                    onCheckboxChange={handleCheckboxChange}
                    assignParameters={assignParameters}
                    onClearFilter={resetFilterRequest}
                  />
                </div>
              )}
              <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mx-4 lg:border-t-0"></div>
              <div className="flex-1 space-y-10">
                {dataResult.data.banner && (
                  <ProductBanner item={dataResult.data.banner} />
                )}
                <ProductItems
                  isShowOptionalAcc={isShowAccessories}
                  optionalAccessoryItems={
                    dataResult.data.optionalAccessoryItems
                  }
                  productItems={dataResult.data.productItems}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </main>
        )}
      </>
    );
  };

  const renderNoProductFound = () => {
    return (
      <div className="nc-NoProductFound space-y-10 lg:space-y-14">
        <div className="flex items-center p-5 border border-slate-200 dark:border-slate-700 rounded-xl overflow-hidden z-0">
          <i className="text-4xl object-contain object-center mr-5 fi fi-rr-search-alt"></i>
          <div>
            <p className="text-black font-semibold">
              We're very sorry, but we couldn't find anything that matched your
              search.
            </p>
            <p className="mb-2">You have a couple options here:</p>
            <ol className="list-decimal pl-4 sm:leading-10">
              <li>Try your search again.</li>
              <li>Click on a product type below to find your product.</li>
              <li>
                <span>
                  If you are looking for a legacy product of ours, email{" "}
                  <strong>
                    <Link
                      to={`mailto:marketing@csc-led.com?Subject=Looking for a legacy product.`}
                    >
                      marketing@csc-led.com
                    </Link>
                  </strong>{" "}
                  and we'll send you exactly what you're after.
                </span>
              </li>
            </ol>
          </div>
        </div>
        <Divider />
        {/**Render product tiles */}
        {dataResult?.data.productTypeList &&
        dataResult?.data.productTypeList.length > 0 ? (
          <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
            {dataResult?.data.productTypeList.map((item, index) => (
              <CSCProductCard
                key={index}
                name={item.productTypeName}
                image={item.imageURL || undefined}
                link={`/product-series/${item.systemId}`}
                linkTarget="_self"
              />
            ))}
          </div>
        ) : (
          <div>Your search did not return any results. That seems odd.</div>
        )}
      </div>
    );
  };

  return (
    <div className={`nc-Products ${className}`} data-nc-id="Products">
      <Helmet>
        <title>Products | CSC LED</title>
      </Helmet>
      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="">
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
              Products
            </h2>
            {/**Bread crumbs */}
            <Breadcrumbs department={_dept} productType={_productType} productSeries={_productSeries} filterItems={dataResult?.data.filterItems}/>
          </div>

          <Divider />
          <div className="scroll-mt-32" id="scrollSeriesTab">
            {isPageLoading ? <SkeletonProductType /> : renderProductBody()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
