import React, { FC, useState, useEffect } from "react";
import axiosInstance from "api/AxiosInstance";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Alert from "./Alert";
import { login } from "store/slices/authSlice";
import Cookies from "js-cookie";
import { decryptData } from "utils/Encryption";
import { useAppDispatch } from "store/hooks";
import { setAccessToken, setTokens } from "utils/auth";
import { getCartData } from "../../store/slices/cartSlice";
import { getCartListData } from "../../store/slices/cartDetailListSlice";
import { getCartIndexListData } from "../../store/slices/cartIndexListSlice";

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from || "/";
  const [rememberMe, setRememberMe] = useState(false);
  const [headermsg, SetHeaderMSG] = useState("");
  const [errormsg, SetErrorMSG] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    RememberMe: false,
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    RememberMe: false,
  });
  const [LoginbuttonClicked, setLoginButtonClicked] = useState(false);

  const handleCheckboxChange = (e: any) => {
    setRememberMe(!rememberMe);
  };

  useEffect(() => {
    setLoginButtonClicked(false);
    const Email = Cookies.get("_AuthE1");
    const Password = Cookies.get("_AuthP2");
    const keepinLogged = Cookies.get("_AuthR3");
    if (keepinLogged && Email && Password) {
      const savedEmail = decryptData(Email);
      const savedPassword = decryptData(Password);
      const rememberme = decryptData(keepinLogged);
      setFormData({ ...formData, email: savedEmail, password: savedPassword });
      setRememberMe(rememberme);
    } else {
      setFormData({ ...formData, email: "", password: "" });
      setRememberMe(false);
    }
  }, []);

  const handleInputChange = (e: any) => {
    const { id, value } = e.target;
    let errorMessage = "";
    switch (id) {
      case "email":
        errorMessage =
          value.trim() === ""
            ? "Your email address is required."
            : "";
        break;
      case "password":
        errorMessage = value.trim() === "" ? "A password is required." : "";
        break;
      default:
        break;
    }   

    setFormData({ ...formData, [id]: value });
    setErrors({ ...errors, [id]: errorMessage });
  };

  const handleBlur = (e: any) => {
    const { id, value } = e.target;
    if (value !== "" && !/^\S+@\S+\.\S+$/.test(value)) {
      setErrors({ ...errors, email: "This is not a valid email address." });
    }
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!formData.email.trim()) {
      newErrors.email = "Your email address is required.";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "This is not a valid email address.";
    }
    if (!formData.password.trim()) {
      newErrors.password = "A password is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length != 0 ? true : false;
  };

  const handleLogin = async (e: any) => {
    e.preventDefault();
    var isValid = validateForm();
    console.log("=error", Object.keys(errors).length);
    if (isValid) {
      console.log("errors", errors);
      return ;
    }
    try {
      if(!LoginbuttonClicked){ 
        setLoginButtonClicked(true);
        // Send data to the API using Axios
        formData.RememberMe = rememberMe;
        const url = "SFRegister/login";
        const params = {
          email: formData.email,
          password: formData.password,
          RememberMe: formData.RememberMe,
        };
        await axiosInstance
          .post(url, params)
          .then((response) => {
           // console.log("=response", response);
            if (
              response.data.loginStatus.isAccountApproved != false &&
              response.data.loginStatus != "" &&
              response.data.loginStatus.errorMsg === "success"
            ) {              
              dispatch(
                login({
                  isAuthenticated: true,
                  userData: {
                    email: formData.email,
                    password: formData.password,
                    userId: response.data.userId,
                    firstName: response.data.firstName,
                    lastName: response.data.lastName,
                  },
                  keepLoggedIn: formData.RememberMe,
                })
              );
              //console.log("Login API Call IF response.data.loginStatus",response.data.loginStatus);
              //Set access tokens
              setTokens(response.data.token,response.data.refreshToken)
              dispatch(getCartListData());
              dispatch(getCartData());
              dispatch(getCartIndexListData());
              navigate(from, { replace: true });
            } else if (response.data.loginStatus.isAccountApproved == false && response.data.loginStatus.headerErrorMsg != "") {
             // console.log("Login API Call ELSE IF headerErrorMsg",response.data.loginStatus);
              SetHeaderMSG(response.data.loginStatus.headerErrorMsg); 
              setLoginButtonClicked(false);   
              SetErrorMSG(response.data.loginStatus.errorMsg);       
            }else if(response.data.loginStatus.isAccountApproved == false && response.data.loginStatus.errorMsg  != ""){
             // console.log("Login API Call ELSE IF errorMsg",response.data.loginStatus);
              setLoginButtonClicked(false);
              SetErrorMSG(response.data.loginStatus.errorMsg);
            } else {
             // console.log("Login API Call ELSE",response.data);
              setLoginButtonClicked(false);
              SetErrorMSG(response.data);
              // Handle other statuses if needed
              console.error("Request failed with status:", response.data);
            }
          });
      }
    } catch (error) {
      console.error("Your Username or password was invalid. Please try again");
      console.error("API Error:", error);
    }
  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login | CSC LED</title>
      </Helmet>

      <div className="container mb-24 lg:mb-32" style={{ marginTop: "-45px" }}>
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Welcome Back
        </h2>
        <div className="sm:mx-5 mx-0">
          <hr
            className="border-t border-gray-300 halloween:border-white"
            style={{ marginTop: "-2.5rem", marginBottom: "2.5rem" }}
          />
        </div>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <div>
            {headermsg && errormsg && (
              <Alert headerErrorMsg={headermsg} errorMessage={errormsg} />
            )}
            {headermsg == "" && errormsg && (<Alert headerErrorMsg={headermsg} errorMessage={errormsg} />)}
          </div>
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={handleLogin}
            method="post"
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200 halloween:text-black">
                Email address
              </span>
              <Input
                id="email"
                type="email"
                className="mt-1"
                onChange={handleInputChange}
                onBlur={handleBlur}
                value={formData.email}
                style={{
                  border:
                    errors.email && errors.email != ""
                      ? "1px solid #fd397a"
                      : "",
                }}
              />
              {errors.email && (
                <span style={{ color: "#fd397a" }}>{errors.email}</span>
              )}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200 halloween:text-black">
                Password
                <Link
                  to="/forgot-pass"
                  className="text-sm text-csccolor halloween:text-black"
                >
                  Forgot password?
                </Link>
              </span>
              <Input
                id="password"
                onChange={handleInputChange}
                type="password"
                className="mt-1"
                style={{
                  border:
                    errors.password && errors.password != ""
                      ? "1px solid #fd397a"
                      : "",
                }}
                value={formData.password}
              />
              {errors.password && (
                <span style={{ color: "#fd397a" }}>{errors.password}</span>
              )}
            </label>
            <label className="block">
              <input
                id="rememberMe"
                type="checkbox"
                checked={rememberMe}
                onChange={handleCheckboxChange}
              />
              <span className="ml-3">Keep me logged in to the website.</span>
            </label>
            {/* onClick={() => setLoggedIn(isLoggedIn)} */}
            <ButtonPrimary type="submit" disabled={LoginbuttonClicked}>Login</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Don't have an account? {` `}
            <Link className="text-csccolor" to="/signup">
              Sign up here.
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
